import React, { Fragment } from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@material-ui/core";
import NoteAddIcon from "@material-ui/icons/NoteAdd";

import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import classNames from "classnames";

import API from "../../api/Insights";
import Format from "../../format/Format";
import RequestInvoiceDialog from "./RequestInvoiceDialog";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2
    }
  });

type Props = WithStyles<typeof styles> & WithSnackbarProps & {};

interface State {
  invoices: any[];
  requestedInvoice: any;
  invoiceDialogOpen: boolean;
}

class Invoices extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      invoices: [],
      requestedInvoice: null,
      invoiceDialogOpen: false
    };

    this.refreshInvoices();
  }

  refreshInvoices() {
    API.partners.invoices.list().then(response => {
      this.setState({
        invoices: response.data.invoices
      });
    });
  }

  handleRequestInvoice = (invoice: any) => {
    this.setState({
      invoiceDialogOpen: true,
      requestedInvoice: invoice
    });
  };

  handleInvoiceDialogClose = (status: boolean, message = "") => {
    this.setState({
      invoiceDialogOpen: false
    });

    if (status) {
      this.refreshInvoices();
      this.props.enqueueSnackbar("Invoice request successfully sent.", {
        variant: "success"
      });
      return;
    }

    if (!status && message.length > 0) {
      this.props.enqueueSnackbar(message, { variant: "error" });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <RequestInvoiceDialog
          open={this.state.invoiceDialogOpen}
          invoice={this.state.requestedInvoice}
          onClose={this.handleInvoiceDialogClose}
        />
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <Paper className={classNames(classes.root, "paper-main")}>
              <div className="paper-header">
                <Typography variant="h5" className="title">
                  Invoices
                </Typography>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Period</TableCell>
                    <TableCell>Partner</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Requested On</TableCell>
                    <TableCell>Paid On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.invoices.map((invoice, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {invoice.start_date} - {invoice.end_date}
                      </TableCell>
                      <TableCell>{invoice.partner.name}</TableCell>
                      <TableCell>
                        {Format.metrics(invoice.amount, "net_revenue")}
                      </TableCell>
                      <TableCell>
                        {invoice.amount >=
                          invoice.partner.minimal_invoice_amount &&
                          !invoice.requested_at && (
                            <Fragment>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={this.handleRequestInvoice.bind(
                                  this,
                                  invoice
                                )}
                              >
                                <NoteAddIcon />
                              </Button>
                            </Fragment>
                          )}
                        {invoice.amount <
                          invoice.partner.minimal_invoice_amount && (
                          <Fragment>
                            A minimal amount of{" "}
                            {invoice.partner.minimal_invoice_amount}&euro; is
                            required.
                          </Fragment>
                        )}
                        {invoice.requested_at && (
                          <Fragment>{invoice.requested_at}</Fragment>
                        )}
                      </TableCell>
                      <TableCell>
                        {invoice.paid_at ? (
                          <Fragment>{invoice.paid_at}</Fragment>
                        ) : (
                          <Fragment>--</Fragment>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(withSnackbar(Invoices));
