import React, { MouseEvent } from "react";
import { NavLink } from "react-router-dom";

import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// Icons
import ShowChartIcon from "@material-ui/icons/ShowChart";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import DescriptionIcon from "@material-ui/icons/Description";

const StatisticsLink = (props: any) => <NavLink to="/statistics" {...props} />;
const AgreementsLink = (props: any) => <NavLink to="/agreements" {...props} />;
const InvoicesLink = (props: any) => <NavLink to="/invoices" {...props} />;

interface LinksHash {
  [key: string]: boolean;
}

interface State {
  links: LinksHash;
}

const styles = (theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing.unit * 4
    }
  });

class Navigation extends React.Component<WithStyles<typeof styles>, State> {
  state = {
    links: {
      statistics: false
    }
  };

  handleClick = (item: string, e: MouseEvent) => {
    e.preventDefault();

    this.setState(state => {
      // Close all open submenues
      let links = {} as LinksHash;
      Object.keys(links).forEach(link => {
        links[link] = false;
      });

      // Togle the menu that was just clicked
      links[item] = !state.links[item];

      return {
        links: links
      };
    });
  };

  render() {
    return (
      <List component="nav">
        <ListItem button component={StatisticsLink}>
          <ListItemIcon>
            <ShowChartIcon />
          </ListItemIcon>
          <ListItemText primary="Statistics" />
        </ListItem>
        <ListItem button component={AgreementsLink}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Agreements" />
        </ListItem>
        <ListItem button component={InvoicesLink}>
          <ListItemIcon>
            <EuroSymbolIcon />
          </ListItemIcon>
          <ListItemText primary="Invoices" />
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles)(Navigation);
