import React, { Fragment } from "react";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import classNames from "classnames";
import {
  Table,
  TableHead,
  TableFooter,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  Paper
} from "@material-ui/core";
import moment, { Moment } from "moment";

import API from "../../api/Insights";
import DatePicker from "../../date/Picker";
import LoadingButton from "../../button/Loading";
import LineChart from "../../charts/Line";
import Format from "../../format/Format";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2
    },
    charts: {
      height: "500px"
    }
  });

interface Props extends WithStyles<typeof styles> {}

interface State {
  startDate: Moment;
  endDate: Moment;
  isLoading: boolean;
  showResults: boolean;
  statistics: any;
  series: any;
  totals: any;
  dates: string[];
  agreements: any;
  selectedMetric: string;
  medium: string
}

class Statistics extends React.Component<Props, State> {
  formatMetric: (metric: string) => (value: any) => any = function(
    metric: string
  ) {
    return function(value: any) {
      return Format.metrics(value, metric);
    };
  };
  
  constructor(props: any) {
    super(props);	
	  this.state = {
	    startDate: moment(),
	    endDate: moment(),
	    isLoading: false,
	    showResults: false,
	    statistics: {} as any,
	    series: {} as any,
	    totals: {} as any,
	    dates: [],
	    agreements: {} as any,
	    selectedMetric: "registrations",
	    medium: 'all'
	  };
	}
  readonly metrics: { [key: string]: string } = {
    registrations: "Registrations",
    net_revenue: "Net Revenue",
    gross_revenue: "Gross Revenue",
    source_share_revenue: "Share"
  };

  handleStartDateChange = (date: Moment) => {
    this.setState({
      startDate: date
    });
  };

  handleEndDateChange = (date: Moment) => {
    this.setState({
      endDate: date
    });
  };

  handleMetricChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      selectedMetric: e.target.value
    });
  };
  
  handleMediumChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      medium: e.target.value,
    });
  };

  handleClick = () => {
    this.setState({
      isLoading: true
    });

    API.partners
      .statistics(this.state.startDate.format(), this.state.endDate.format(), this.state.medium)
      .then(response => {
        let series: any = {
          registrations: {},
          net_revenue: {},
          gross_revenue: {},
          source_share_revenue: {}
        };

        // Transform series in the proper format for charting
        Object.keys(response.data.statistics).forEach(date => {
          Object.keys(response.data.statistics[date]).forEach(agreement_id => {
            Object.keys(response.data.statistics[date][agreement_id]).forEach(
              metric => {
                if (!series[metric][agreement_id]) {
                  series[metric][agreement_id] = {
                    name:
                      response.data.agreements[agreement_id].project.name.toString() +
                      " " +
                      response.data.agreements[
                        agreement_id
                      ].subproject.name.toString() +
                      " " +
                      response.data.agreements[agreement_id].domain,
                    values: []
                  };
                }

                series[metric][agreement_id].values.push(
                  response.data.statistics[date][agreement_id][metric]
                );
              }
            );
          });
        });

        this.setState({
          isLoading: false,
          showResults: true,
          dates: Object.keys(response.data.statistics),
          agreements: response.data.agreements || {},
          totals: response.data.totals,
          series: series,
          statistics: response.data.statistics
        });
      });
  };
  render() {
    const { classes } = this.props;
    const { dates, showResults } = this.state;
    let y = [];
    if (showResults) {
      y = Object.keys(this.state.series[this.state.selectedMetric]).map(
        agreement_id => {
          return this.state.series[this.state.selectedMetric][agreement_id];
        }
      );
    }

    return (
      <Fragment>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <Paper className={classNames(classes.root, "paper-main")}>
              <div className="paper-header">
                <Typography variant="h5" className="title">
                  Statistics
                </Typography>
              </div>
              <Grid container spacing={16}>
                <Grid item>
                  <DatePicker
                    value={this.state.startDate}
                    onChange={this.handleStartDateChange}
                    label="Start Date"
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    value={this.state.endDate}
                    onChange={this.handleEndDateChange}
                    label="End Date"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel htmlFor="medium">Medium</InputLabel>
                    <Select
                      value={this.state.medium}
                      onChange={this.handleMediumChange}
                      inputProps={{
                        name: 'medium',
                        id: 'medium',
                      }}
                      autoWidth
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="ios">iOS</MenuItem>
                      <MenuItem value="android">Android</MenuItem>
                      <MenuItem value="web">Web</MenuItem>
                    </Select>
                </FormControl>
              </Grid>
                <Grid item>
                  <LoadingButton
                    isLoading={this.state.isLoading}
                    onClick={this.handleClick}
                    color="primary"
                  >
                    Go!
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {showResults && (
            <Fragment>
              <Grid item>
                <Paper className={classNames(classes.root)}>
                  <Grid container direction="column" spacing={16}>
                    <Grid item>
                      <FormControl>
                        <InputLabel htmlFor="metric">Metric</InputLabel>
                        <Select
                          value={this.state.selectedMetric}
                          onChange={this.handleMetricChange}
                          inputProps={{
                            name: "metric",
                            id: "metric"
                          }}
                        >
                          <MenuItem value="registrations">
                            Registrations
                          </MenuItem>
                          <MenuItem value="net_revenue">Net Revenue</MenuItem>
                          <MenuItem value="gross_revenue">
                            Gross Revenue
                          </MenuItem>
                          <MenuItem value="source_share_revenue">
                            Share
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.charts}>
                      <LineChart
                        x={dates}
                        y={y}
                        label={this.metrics[this.state.selectedMetric]}
                        format={this.formatMetric(this.state.selectedMetric)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item>
                <Paper className={classNames(classes.root)}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Partner</TableCell>
                        <TableCell>Agreement</TableCell>
                        <TableCell>Registrations</TableCell>
                        <TableCell>Net Revenue</TableCell>
                        <TableCell>Gross Revenue</TableCell>
                        <TableCell>Share</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(this.state.agreements).map(agreement_id => (
                        <TableRow key={agreement_id}>
                          <TableCell>
                            {this.state.agreements[agreement_id].partner.name}
                          </TableCell>
                          <TableCell>
                            {this.state.agreements[agreement_id].project.name}{" "}
                            {this.state.agreements[agreement_id].subproject.name}{" "}
                            {this.state.agreements[agreement_id].domain}{" "}
                            {this.state.agreements[agreement_id].name ? "(" + this.state.agreements[agreement_id].name + ")" : ''}
                          </TableCell>
                          <TableCell>
                            {Format.metrics(
                              this.state.totals.agreements[agreement_id]
                                .registrations,
                              "registrations"
                            )}
                          </TableCell>
                          <TableCell>
                            {Format.metrics(
                              this.state.totals.agreements[agreement_id].net_revenue,
                              "net_revenue"
                            )}
                          </TableCell>
                          <TableCell>
                            {Format.metrics(
                              this.state.totals.agreements[agreement_id]
                                .gross_revenue,
                              "gross_revenue"
                            )}
                          </TableCell>
                          <TableCell>
                            {Format.metrics(
                              this.state.totals.agreements[agreement_id]
                                .source_share_revenue,
                              "source_share_revenue"
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell>Totals</TableCell>
                        <TableCell>
                          {Format.metrics(
                            this.state.totals.registrations,
                            "registrations"
                          )}
                        </TableCell>
                        <TableCell>
                          {Format.metrics(
                            this.state.totals.net_revenue,
                            "net_revenue"
                          )}
                        </TableCell>
                        <TableCell>
                          {Format.metrics(
                            this.state.totals.gross_revenue,
                            "gross_revenue"
                          )}
                        </TableCell>
                        <TableCell>
                          {Format.metrics(
                            this.state.totals.source_share_revenue,
                            "source_share_revenue"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Paper>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Statistics);
