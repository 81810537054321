import React from "react";
import Grid from "@material-ui/core/Grid";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker
} from "material-ui-pickers";
import { Moment } from "moment";

interface Props {
  value: Moment;
  label?: string;
  onChange: (date: Moment) => void;
}

interface State {
  date: object;
}

class Picker extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      date: props.initialValue
    };
  }

  handleDateChange = (date: Moment) => {
    this.props.onChange(date);
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          keyboard
          autoOk
          label={this.props.label ? this.props.label : "Date"}
          format="DD-MM-YYYY"
          onChange={this.handleDateChange}
          adornmentPosition="start"
          showTodayButton
          value={this.props.value}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default Picker;
