import React, { Fragment } from "react";
import AuthenticatedRoute from "../authentication/Route";
import { Switch } from "react-router-dom";

import Statistics from "../pages/statistics/Statistics";
import Agreements from "../pages/agreements/Agreements";
import Invoices from "../pages/invoices/Invoices";
import Settings from "../pages/settings/Settings";

class Routes extends React.Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <AuthenticatedRoute path="/statistics" component={Statistics} />
          <AuthenticatedRoute path="/agreements" component={Agreements} />
          <AuthenticatedRoute path="/invoices" component={Invoices} />
          <AuthenticatedRoute path="/settings" component={Settings} />
        </Switch>
      </Fragment>
    );
  }
}

export default Routes;
