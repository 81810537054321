import axios, {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosResponse
} from "axios";

export type AuthInterceptor = number;

class Client {
  static API_URL = process.env.REACT_APP_API_URL;

  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: Client.API_URL
    });
  }

  post(path: string, params: object = {}, headers: object = {}) {
    return this.instance.post(path, params, { headers: headers });
  }

  get(path: string, params: object = {}, headers: object = {}) {
    return this.instance.get(path, { params: params, headers: headers });
  }

  delete(path: string, params: object = {}, headers: object = {}) {
    return this.instance.delete(path, { params: params, headers: headers });
  }

  put(path: string, params: object = {}, headers: object = {}) {
    return this.instance.put(path, params, { headers: headers });
  }

  addResponseInterceptor(
    successCb: (response: any) => any,
    errorCb: (error: any) => any
  ): AuthInterceptor {
    return this.instance.interceptors.response.use(successCb, errorCb);
  }

  removeResponseInterceptor(interceptor: AuthInterceptor) {
    this.instance.interceptors.response.eject(interceptor);
  }
}

export default new Client();
