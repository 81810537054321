import React, {
  Component,
  MouseEvent,
  KeyboardEvent,
  ChangeEvent
} from "react";
import {
  Avatar,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Input,
  InputLabel,
  Paper,
  Typography
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { Redirect } from "react-router-dom";
import { withSnackbar, WithSnackbarProps } from "notistack";

import API from "../../api/Insights";

import {
  Context as AuthenticationContext,
  User
} from "../../authentication/Context";

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
        .spacing.unit * 3}px`
    },
    avatar: {
      margin: theme.spacing.unit,
      backgroundColor: "white"
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing.unit
    },
    submit: {
      marginTop: theme.spacing.unit * 3
    }
  });

interface State {
  user: User;
  email: string;
  password: string;
  rememberMe: boolean;
}

type Props = WithStyles<typeof styles> &
  WithSnackbarProps & {
    signIn: (user: User) => void;
  };

class Login extends Component<Props, State> {
  static contextType: typeof AuthenticationContext = AuthenticationContext;

  constructor(props: any) {
    super(props);

    this.state = {
      user: {
        authenticated: false,
        username: "",
        id: null
      },
      email: "",
      password: "",
      rememberMe: false
    };
  }

  handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.target.value });
  };

  handleRememberMeChange = (e: ChangeEvent<{}>, checked: boolean) => {
    this.setState({ rememberMe: checked });
  };

  handleLogin = (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();

    API.signIn(
      this.state.email,
      this.state.password,
      this.state.rememberMe
    ).then(
      (response: any) => {
        this.props.signIn({
          authenticated: true,
          username: response.data.data.name,
          id: response.data.data.id
        });
      },
      (error: any) => {
        // Display login problem snackbar
        this.props.enqueueSnackbar(
          "Please enter a valid username and password.",
          { variant: "warning" }
        );
      }
    );
  };

  render() {
    // Prevent rendering before user info has been checked
    if (!this.context) {
      return null;
    }

    if (this.context.authenticated) {
      return <Redirect to="/statistics" />;
    }

    return (
      <main className={this.props.classes.main}>
        <Paper className={this.props.classes.paper}>
          <Avatar className={this.props.classes.avatar}>
            <img src={process.env.PUBLIC_URL + "/favicon-32x32.png"} />
          </Avatar>
          <Typography variant="h5">XS Partners</Typography>
          <form className={this.props.classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                value={this.state.email}
                onChange={this.handleEmailChange}
                type="email"
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox checked={this.state.rememberMe} color="primary" />
              }
              name="remember_me"
              id="remember_me"
              label="Remember me"
              onChange={this.handleRememberMeChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={this.props.classes.submit}
              onClick={this.handleLogin}
            >
              Sign in
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(withSnackbar(Login));
