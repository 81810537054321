import { format, formatDefaultLocale } from "d3-format";

class Format {
  constructor() {
    formatDefaultLocale({
      decimal: ".",
      thousands: ",",
      grouping: [3],
      currency: ["€", ""]
    });
  }

  capialize(value: string) {
    return (
      value && value.charAt(0).toUpperCase() + value.substr(1).toLowerCase()
    );
  }

  default(value: any, defaultValue: any) {
    if (value === undefined || isNaN(value) || !value) {
      return defaultValue;
    }

    return value;
  }

  metrics(value: any, type: string) {
    switch (type) {
      case "registrations":
      case "payments":
      case "unique_buyers":
      case "first_buyers":
      case "actives":
      case "actives_android":
      case "actives_ios":
      case "web_to_mobile":
      case "logins":
      case "actives_per_cu_1":
      case "actives_per_cu_2":
      case "actives_per_cu_3":
      case "actives_per_cu_4":
      case "actives_per_cu_5":
      case "actives_per_cu_6":
      case "actives_per_cu_7":
      case "unique_buyers_android":
      case "unique_buyers_ios":
      case "web_to_ios":
      case "web_to_android":
      case "repeatative_buyers":
        return format(",.2d")(value);
        break;
      case "gross_revenue":
      case "net_revenue":
      case "arpu":
      case "arppu":
      case "arpdau":
      case "revenue_per_transaction":
      case "ltv":
      case "revenue_per_cu_2":
      case "revenue_per_cu_3":
      case "revenue_per_cu_4":
      case "revenue_per_cu_5":
      case "revenue_per_cu_6":
      case "revenue_per_cu_7":
      case "source_share_revenue":
      case "xs_share_revenue":
        return this.currency(value);
        break;
      case "new_vs_returning":
      case "buyers_vs_actives":
        return this.percent(value);
        break;
      case "total_conversion_to_paying_time":
      case "average_paying_conversion_time":
        var days = Math.round(value / (3600 * 24));
        var hours = Math.round((value - days * 3600 * 24) / 3600);
        var minutes = Math.round(
          (value - days * 3600 * 24 - hours * 3600) / 60
        );
        var result = "";

        if (days > 0) {
          result += days + "d";
        }

        if (hours > 0) {
          result += " " + hours + "h";
        }

        if (minutes > 0) {
          result += " " + minutes + "min";
        }

        return result;
        break;
      default:
        return format(",.2f")(value);
    }
  }

  currency(value: number) {
    return format("$,.2f")(value);
  }

  percent(value: number) {
    return format(".2%")(value);
  }

  number(value: number) {
    return format(",.2d")(value);
  }

  numberFriendly(value: number | string) {
    if (isNaN(value as any)) {
      return value;
    }

    value = parseInt(value as any);

    return format("~s")(value);
  }
}

const formatter = new Format();
export default formatter;
