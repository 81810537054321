import React, { Component, Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Context as AuthenticationContext,
  User
} from "./authentication/Context";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { withSnackbar, WithSnackbarProps } from "notistack";
import blue from "@material-ui/core/colors/blue";
import Login from "./pages/login/Login";
import Main from "./pages/main/Main";
import API from "./api/Insights";
import moment from "moment";
import "./App.css";

import { Route, Switch } from "react-router-dom";
import { AuthInterceptor } from "./http/Client";

const theme = createMuiTheme({
  palette: {
    primary: blue
  }
});

interface Props extends WithSnackbarProps {}
interface State {
  user: User;
  authChecked: boolean;
}

class App extends Component<Props, State> {
  authInterceptor: AuthInterceptor;

  constructor(props: any) {
    super(props);

    // Set default date format
    moment.defaultFormat = "DD-MM-YYYY";
    // Set week start to Monday
    moment.updateLocale("en", {
      week: {
        dow: 1, // First day of week is Monday
        doy: 7 // The week that contains Jan 7th is the first week of the year.
      }
    });

    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);

    this.state = {
      user: {
        authenticated: false,
        username: "",
        id: null
      },
      authChecked: false
    };

    this.authInterceptor = {} as AuthInterceptor;

    // Check authentication status
    API.checkAuth()
      .then((user: User) => {
        this.setState({
          user: user,
          authChecked: true
        });
      })
      .catch(() => {
        this.setState({
          authChecked: true
        });
      });
  }

  signIn(user: User) {
    this.setState({
      user: user
    });

    this.authInterceptor = API.onRequestAuthFailure(response => {
      // Show session expired message
      this.props.enqueueSnackbar("Session expired. Please login again.", {
        variant: "info"
      });

      // Logout the user from the main interface
      this.setState({
        user: {
          authenticated: false,
          username: "",
          id: null
        }
      });

      // Remove interceptor
      API.offRequestAuthFailure(this.authInterceptor);
    });
  }

  signOut() {
    API.signOut();

    // Show session expired message
    this.props.enqueueSnackbar("Logged out successfully.", {
      variant: "info"
    });

    this.setState({
      user: {
        authenticated: false,
        username: "",
        id: null
      }
    });
  }

  render() {
    // Prevent rendering if we haven't checked
    // the authentication status of the user
    // This prevenst the login page being displayed and users
    // being redirected to the dashboard on a full page refresh
    if (!this.state.authChecked) {
      return null;
    }

    return (
      <AuthenticationContext.Provider value={this.state.user}>
        <CssBaseline />

        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route
              path="/"
              exact
              render={props => <Login {...props} signIn={this.signIn} />}
            />
            <Route
              render={props => <Main {...props} signOut={this.signOut} />}
            />
          </Switch>
        </MuiThemeProvider>
      </AuthenticationContext.Provider>
    );
  }
}

export default withSnackbar(App);
