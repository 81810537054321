import React from "react";
import { Grid } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { Formik, Field, Form } from "formik";
import { withSnackbar, WithSnackbarProps } from "notistack";

import API from "../../api/Insights";
import LoadingButton from "../../button/Loading";

const styles = (theme: Theme) => createStyles({});

type Props = WithStyles<typeof styles> & WithSnackbarProps & {};

interface State {}

interface Values {
  phone: string;
}

class Profile extends React.Component<Props, State> {
  handleChangeProfile = (
    values: Values,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    API.partners.settings.profile(values.phone).then(response => {
      setSubmitting(false);
      if (!response.data.status) {
        this.props.enqueueSnackbar(response.data.message, { variant: "error" });
        return;
      }

      // All ok, let the user know
      this.props.enqueueSnackbar(response.data.message, { variant: "success" });
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Formik
        initialValues={{
          phone: ""
        }}
        validate={values => {
          const errors: Partial<Values> = {};
          if (!values.phone) {
            errors.phone = "Required";
          }

          return errors;
        }}
        onSubmit={this.handleChangeProfile}
        render={({ submitForm, isSubmitting, values, setFieldValue }) => (
          <Form>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <Field name="phone" label="Phone" component={TextField} />
              </Grid>
              <Grid item>
                <LoadingButton
                  color="primary"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default withStyles(styles)(withSnackbar(Profile));
