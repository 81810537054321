import React, { Fragment } from "react";
import {
  Avatar,
  Button,
  Dialog,
  CircularProgress,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EuroIcon from "@material-ui/icons/EuroSymbol";

import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import classNames from "classnames";

import API from "../../api/Insights";

const styles = (theme: Theme) =>
  createStyles({
    input: {},
    button: {},
    inputIcon: {
      color: "gray"
    }
  });

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  invoice: any;
  onClose: (status: boolean, message?: string) => void;
}

interface State {
  comments: string;
  invoice: File | null;
  isLoading: boolean;
  hasError: boolean;
}

class RequestInvoiceDialog extends React.Component<Props, State> {
  state = {
    comments: "",
    invoice: null,
    isLoading: false,
    hasError: false
  };

  handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files![0];
    this.setState({
      invoice: file,
      hasError: false
    });
  };

  handleCommentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      comments: e.currentTarget.value
    });
  };

  handleSubmit = () => {
    // Invoice is required
    if (!this.state.invoice) {
      this.setState({
        hasError: true
      });
      return;
    }

    this.setState({
      isLoading: true
    });

    const data = new FormData();
    data.append("invoice", this.state.invoice!);
    data.append("comments", this.state.comments);
    data.append("id", this.props.invoice.id);

    API.partners.invoices.submit(data).then((response: any) => {
      this.setState({
        isLoading: false
      });
      this.props.onClose(response.data.status, response.data.message);
    });
  };

  handleClose = () => {
    this.props.onClose(false);
  };

  render() {
    const { classes } = this.props;

    if (!this.props.open) {
      return null;
    }

    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Request Payment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To request a payment you need to upload an invoice document. Allowed
            formats are pdf, images, doc, docx, zip, xlsx.
          </DialogContentText>
          <List>
            <ListItem>
              <Avatar>
                <DateRangeIcon />
              </Avatar>
              <ListItemText
                primary={this.props.invoice.start_date.toString()}
                secondary="Start Date"
              />
              <ListItemText
                primary={this.props.invoice.end_date.toString()}
                secondary="End Date"
              />
            </ListItem>
            <ListItem>
              <Avatar>
                <EuroIcon />
              </Avatar>
              <ListItemText
                primary={this.props.invoice.amount.toString() + "€"}
                secondary="Amount"
              />
            </ListItem>
            <ListItem>
              <TextField
                value={this.state.comments}
                onChange={this.handleCommentsChange}
                autoFocus
                margin="dense"
                id="comments"
                label="Comments"
                fullWidth
                multiline
                rows="4"
              />
            </ListItem>
            <ListItem>
              <input
                accept="image/*,.pdf,.doc,.docx,.zip,.txt,.xls"
                className={classes.input}
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={this.handleFileSelect}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="contained"
                  component="span"
                  className={classes.button}
                  disabled={this.state.isLoading}
                >
                  Upload Invoice
                </Button>
              </label>
              {this.state.invoice && (
                <ListItemText primary={this.state.invoice!["name"]} />
              )}
              {this.state.hasError && (
                <ListItemText
                  primary="Please upload an invoice document."
                  primaryTypographyProps={{ color: "error" }}
                />
              )}
            </ListItem>
            <ListItem>{this.state.isLoading && <CircularProgress />}</ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClose}
            color="secondary"
            disabled={this.state.isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            disabled={this.state.isLoading}
          >
            Send Request
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(RequestInvoiceDialog);
