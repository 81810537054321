import React, { Context } from "react";
import { Route, Redirect } from "react-router-dom";
import { Context as AuthenticationContext } from "./Context";

interface Props {
  path: string;
  exact?: boolean;
  component: React.ReactType<any>;
}

class AuthenticatedRoute extends React.Component<Props, {}> {
  static contextType: typeof AuthenticationContext = AuthenticationContext;

  render() {
    const { component: Component, path } = this.props;

    if (this.context.authenticated === false) {
      return <Redirect to="/" />;
    }

    return (
      <Route
        path={path}
        exact={this.props.exact}
        render={() => {
          return <Component />;
        }}
      />
    );
  }
}

export default AuthenticatedRoute;
