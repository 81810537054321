import React, { Fragment } from "react";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TableCell
} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import API from "../../api/Insights";
import classNames from "classnames";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2
    }
  });

interface Props extends WithStyles<typeof styles> {}

interface State {
  agreements: any[];
}

class Agreements extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      agreements: []
    };

    API.partners.agreements().then(response => {
      this.setState({
        agreements: response.data.agreements
      });
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <Paper className={classNames(classes.root, "paper-main")}>
              <div className="paper-header">
                <Typography variant="h5" className="title">
                  Agreements
                </Typography>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Partner</TableCell>
                    <TableCell>Project</TableCell>
                    <TableCell>Revenue Share %</TableCell>
                    <TableCell>Payment Tax</TableCell>
                    <TableCell>First Payment %</TableCell>
                    <TableCell>First Payment Tax</TableCell>
                    <TableCell>CPA</TableCell>
                    <TableCell>Start Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.agreements.map(agreement => (
                    <TableRow key={agreement.id}>
                      <TableCell>{agreement.partner.name}</TableCell>
                      <TableCell>
                        {agreement.project.name} {agreement.subproject.name} {agreement.name ? "(" + agreement.name + ")" : ''}
                      </TableCell>
                      <TableCell>{agreement.commission.rs}%</TableCell>
                      <TableCell>{agreement.commission.pt}</TableCell>
                      <TableCell>{agreement.commission.fpp}%</TableCell>
                      <TableCell>{agreement.commission.fpt}</TableCell>
                      <TableCell>{agreement.commission.cpa}</TableCell>
                      <TableCell>{agreement.commission.start_date}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Agreements);
