import Lockr from "lockr";

class Local {
  constructor() {
    Lockr.prefix = "partner_";
  }

  get(key: string) {
    return Lockr.get(key);
  }

  set(key: string, value: string | number | [] | object) {
    return Lockr.set(key, value);
  }

  delete(key: string) {
    return Lockr.rm(key);
  }
}

export default new Local();
