import React from "react";
import { Grid } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { Formik, Field, Form } from "formik";
import { withSnackbar, WithSnackbarProps } from "notistack";

import API from "../../api/Insights";
import LoadingButton from "../../button/Loading";

const styles = (theme: Theme) => createStyles({});

type Props = WithStyles<typeof styles> & WithSnackbarProps & {};

interface State {}

interface Values {
  password: string;
  newPassword: string;
  newPasswordConfirmed: string;
}

class Password extends React.Component<Props, State> {
  handleChangePassword = (
    values: Values,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    API.partners.settings
      .password(
        values.password,
        values.newPassword,
        values.newPasswordConfirmed
      )
      .then(
        response => {
          setSubmitting(false);
          if (!response.status) {
            this.props.enqueueSnackbar(response.data.full_messages[0], {
              variant: "error"
            });
            return;
          }

          // All ok, let the user know
          this.props.enqueueSnackbar(response.data.message, {
            variant: "success"
          });
        },
        error => {
          setSubmitting(false);
          let message = "";
          if (error.response.data.errors.full_messages) {
            message = error.response.data.errors.full_messages[0];
          } else {
            message = error.response.data.errors[0];
          }

          this.props.enqueueSnackbar(message, { variant: "error" });
        }
      );
  };

  render() {
    return (
      <Formik
        initialValues={{
          password: "",
          newPassword: "",
          newPasswordConfirmed: ""
        }}
        validate={values => {
          const errors: Partial<Values> = {};
          if (!values.password) {
            errors.password = "Required";
          }
          if (!values.newPassword) {
            errors.newPassword = "Required";
          }

          if (values.newPassword && values.newPassword.length < 8) {
            errors.newPassword =
              "Password too short (at least 8 characters required)";
          }

          if (!values.newPasswordConfirmed) {
            errors.newPasswordConfirmed = "Required";
          }
          if (values.newPassword != values.newPasswordConfirmed) {
            errors.newPasswordConfirmed = "Passwords do not match";
          }

          return errors;
        }}
        onSubmit={this.handleChangePassword}
        render={({ submitForm, isSubmitting, values, setFieldValue }) => (
          <Form>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <Field
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  type="password"
                  label="Current"
                  component={TextField}
                />
              </Grid>
              <Grid item>
                <Field
                  name="newPassword"
                  type="password"
                  label="New"
                  component={TextField}
                />
              </Grid>
              <Grid item>
                <Field
                  name="newPasswordConfirmed"
                  type="password"
                  label="Confirm New"
                  component={TextField}
                />
              </Grid>
              <Grid item>
                <LoadingButton
                  color="primary"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default withStyles(styles)(withSnackbar(Password));
