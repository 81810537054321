import Client, { AuthInterceptor } from "../http/Client";
import LocalStorageAPI from "../storage/Local";
import { User } from "../authentication/Context";

type AuthIndex = "access-token" | "token-type" | "client" | "expiry" | "uid";
type AuthHeaders = { [index in AuthIndex]: string };
class Insights {
  headers: AuthHeaders;

  constructor() {
    this.headers = {
      "access-token": "",
      "token-type": "",
      client: "",
      expiry: "",
      uid: ""
    };

    // Intercept every response and check
    // if authentication headers have changed
    Client.addResponseInterceptor(
      (response: any) => {
        // No previously existing headers
        if (!this.headers || !this.headers["access-token"]) {
          this.headers = {
            "access-token": response.headers["access-token"],
            "token-type": response.headers["token-type"],
            client: response.headers["client"],
            expiry: response.headers["expiry"],
            uid: response.headers["uid"]
          };

          return response;
        }

        // Updated headers
        if (response.headers && response.headers["access-token"]) {
          let updated = false;

          if (
            !this.headers["access-token"] ||
            this.headers["access-token"] != response.headers["access-token"]
          ) {
            this.headers["access-token"] = response.headers["access-token"];
            updated = true;
          }

          if (
            !this.headers["expiry"] ||
            this.headers["expiry"] != response.headers["expiry"]
          ) {
            this.headers["expiry"] = response.headers["expiry"];
            updated = true;
          }

          if (
            !this.headers["client"] ||
            this.headers["client"] != response.headers["client"]
          ) {
            this.headers["client"] = response.headers["client"];
            updated = true;
          }

          if (
            !this.headers["uid"] ||
            this.headers["uid"] != response.headers["uid"]
          ) {
            this.headers["uid"] = response.headers["uid"];
            updated = true;
          }
          // Update local storage too
          if (updated) {
            LocalStorageAPI.set("auth_headers", this.headers);
          }
        }

        return response;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  }

  protected get(url: string, params: object = {}) {
    return Client.get(url, params, this.headers);
  }

  protected post(url: string, params: object = {}) {
    return Client.post(url, params, this.headers);
  }

  protected put(url: string, params: object = {}) {
    return Client.put(url, params, this.headers);
  }

  signOut() {
    let promise = Client.delete(
      "/partners/frontend/auth/sign_out.json",
      {},
      this.headers
    );
    this.headers = {
      "access-token": "",
      "token-type": "",
      client: "",
      expiry: "",
      uid: ""
    };
    LocalStorageAPI.delete("auth_headers");

    return promise;
  }

  signIn(email: string, password: string, remember_me: boolean = false) {
    let promise = new Promise((resolve, reject) => {
      Client.post("/partners/frontend/auth/sign_in.json", {
        email: email,
        password: password
      })
        .then((response: any) => {
          // Store them in local storage too, so that we can implement a remember me
          // functionality
          if (remember_me) {
            LocalStorageAPI.set("auth_headers", this.headers);
          }

          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });

    return promise;
  }

  onRequestAuthFailure(callback: (response: any) => void): AuthInterceptor {
    return Client.addResponseInterceptor(
      (response: any) => {
        // Normal successful request, do nothing
        return response;
      },
      (error: any) => {
        // Request failed because of authentication
        if (error.response.status == 401) {
          callback(error);
        }

        return Promise.reject(error);
      }
    );
  }

  offRequestAuthFailure(interceptor: AuthInterceptor) {
    Client.removeResponseInterceptor(interceptor);
  }

  checkAuth(): Promise<User> {
    this.headers =
      this.headers["access-token"].length > 0
        ? this.headers
        : (LocalStorageAPI.get("auth_headers") as AuthHeaders);
    if (!this.headers || Object.keys(this.headers).length == 0) {
      return Promise.reject();
    }

    let promise = new Promise<User>((resolve, reject) => {
      Client.get(
        "/partners/frontend/auth/validate_token.json",
        {},
        this.headers
      )
        .then(response => {
          resolve({
            username: response.data.data.name,
            id: response.data.data.id,
            authenticated: true
          });
        })
        .catch(() => {
          reject();
        });
    });

    return promise;
  }

  partners = {
    statistics: (startDate: string, endDate: string, medium: string) => {
      return this.get("/partners/frontend/statistics.json", {
        start_date: startDate,
        end_date: endDate,
        medium: medium
      });
    },
    agreements: () => {
      return this.get("/partners/frontend/agreements.json");
    },
    invoices: {
      list: () => {
        return this.get("/partners/frontend/invoices.json");
      },
      submit: (data: FormData) => {
        return this.post("/partners/frontend/invoices.json", data);
      }
    },
    settings: {
      password: (
        currentPassword: string,
        newPassword: string,
        newPasswordConfirmed: string
      ) => {
        return this.put("/partners/frontend/auth/password.json", {
          password: newPassword,
          password_confirmation: newPasswordConfirmed,
          current_password: currentPassword
        });
      },
      email: (password: string, email: string, emailConfirmed: string) => {
        return this.post("/partners/frontend/settings/email.json", {
          password: password,
          email: email,
          email_confirmation: emailConfirmed
        });
      },
      profile: (phone: string) => {
        return this.post("/partners/frontend/settings/profile.json", {
          phone: phone
        });
      }
    }
  };
}

export default new Insights();
