import React from "react";
import { Grid } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { Formik, Field, Form } from "formik";
import { withSnackbar, WithSnackbarProps } from "notistack";

import API from "../../api/Insights";
import LoadingButton from "../../button/Loading";

const styles = (theme: Theme) => createStyles({});

type Props = WithStyles<typeof styles> & WithSnackbarProps & {};

interface State {}

interface Values {
  password: string;
  email: string;
  emailConfirmed: string;
}

class Email extends React.Component<Props, State> {
  handleChangeEmail = (
    values: Values,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    API.partners.settings
      .email(values.password, values.email, values.emailConfirmed)
      .then(response => {
        setSubmitting(false);
        if (!response.data.status) {
          this.props.enqueueSnackbar(response.data.message, {
            variant: "error"
          });
          return;
        }

        // All ok, let the user know
        this.props.enqueueSnackbar(response.data.message, {
          variant: "success"
        });
      });
  };

  render() {
    return (
      <Formik
        initialValues={{
          password: "",
          email: "",
          emailConfirmed: ""
        }}
        validate={values => {
          const errors: Partial<Values> = {};
          if (!values.password) {
            errors.password = "Required";
          }
          if (!values.email) {
            errors.email = "Required";
          }

          if (
            values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.emailConfirmed) {
            errors.emailConfirmed = "Required";
          }
          if (values.email != values.emailConfirmed) {
            errors.emailConfirmed = "Emails do not match";
          }

          return errors;
        }}
        onSubmit={this.handleChangeEmail}
        render={({ submitForm, isSubmitting, values, setFieldValue }) => (
          <Form>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                <Field
                  name="password"
                  autoComplete="current-password"
                  type="password"
                  label="Password"
                  component={TextField}
                />
              </Grid>
              <Grid item>
                <Field
                  name="email"
                  id="email"
                  type="email"
                  label="New Email"
                  component={TextField}
                />
              </Grid>
              <Grid item>
                <Field
                  name="emailConfirmed"
                  type="email"
                  label="Confirm New Email"
                  component={TextField}
                />
              </Grid>
              <Grid item>
                <LoadingButton
                  color="primary"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    );
  }
}

export default withStyles(styles)(withSnackbar(Email));
