import React from "react";

// Load only the necessary components from echarts, this makes the
// bundle size considerably smaller
import ECharts from "echarts/lib/echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";
import "echarts/lib/component/legendScroll";
import "echarts/lib/component/markLine";
import "echarts/lib/component/markPoint";
import "echarts/lib/component/toolbox";

type Formatter = (value: string | number) => string | number;
export type yType = {
  name: string;
  values: string[] | number[];
  options?: { disableMarkers: boolean };
}[];

interface Props {
  label?: string;
  disableMarkers?: boolean;
  x: string[] | number[];
  y: yType;
  format?: Formatter;
  theme?: string;
  onRender?: (chart: ECharts.ECharts) => void;
}

class Bar extends React.Component<Props> {
  reference = React.createRef<HTMLDivElement>();

  format: Formatter;
  theme: string;
  chartType: string = "bar";
  instance: ECharts.ECharts | null;

  constructor(props: any) {
    super(props);

    this.format = this.props.format ? this.props.format : this.defaultFormat;
    this.theme = this.props.theme ? this.props.theme : "light";
    this.instance = null;
  }

  defaultFormat(value: string | number) {
    return value;
  }

  formatter(params: any) {
    if (!(params instanceof Array)) {
      params = [params];
    }

    // Sort descending by value
    params.sort(function(a: any, b: any) {
      return b.value - a.value;
    });

    var tooltip = params[0].name + "<br />";
    for (var i = 0; i < params.length; i++) {
      var color =
        '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
        params[i].color +
        ';"></span>';
      tooltip +=
        color +
        " " +
        params[i].seriesName +
        " " +
        this.format(params[i].value) +
        "<br />";
    }

    return tooltip;
  }

  componentDidMount() {
    this.instance = ECharts.init(this.reference.current!, this.theme, {
      width: "auto",
      height: "auto"
    });
    let options: any = {
      title: {
        text: this.props.label,
        left: "center"
      },
      tooltip: {
        formatter: this.formatter.bind(this)
      },
      legend: {
        show: true,
        type: "scroll",
        top: "bottom"
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true,
            title: "Save Image"
          },
          restore: {
            show: true,
            title: "Reset"
          },
          magicType: {
            show: true,
            title: "Switch Chart",
            type: ["line", "bar"]
          }
        }
      },
      xAxis: {
        type: "category",
        data: this.props.x,
        axisPointer: {
          show: true
        }
      },
      yAxis: {},
      series: []
    };

    // Add all supplied series
    options.series = this.createSeries();

    this.instance.setOption(options);
    this.instance.on("rendered", () => {
      if (this.props.onRender) {
        this.props.onRender(this.instance as ECharts.ECharts);
      }
    });
  }

  createSeries(): any[] {
    let series = [];
    for (var i = 0; i < this.props.y.length; i++) {
      var entry: any = {
        name: this.props.y[i].name,
        data: this.props.y[i].values,
        smooth: true,
        type: this.chartType
      };

      let seriesDisableMarkers = false;
      if (this.props.y[i].options! && this.props.y[i].options!.disableMarkers) {
        seriesDisableMarkers = this.props.y[i].options!.disableMarkers;
      }

      if (!this.props.disableMarkers && !seriesDisableMarkers) {
        entry.markPoint = {
          symbol: "pin",
          label: {
            formatter: (params: any) => {
              return this.format(params.value);
            }
          },
          data: [
            { type: "max", name: "Maximum", itemStyle: { color: "#23bc2b" } },
            { type: "min", name: "Minimum", itemStyle: { color: "#B71C1C" } }
          ]
        };
        entry.markLine = {
          label: {
            formatter: (params: any) => {
              return this.format(params.value);
            }
          },
          data: [{ type: "average", name: "Average" }]
        };
      }

      series.push(entry);
    }

    return series;
  }

  updateChart() {
    if (this.instance) {
      let options: any = {
        title: {
          text: this.props.label
        },
        xAxis: {
          data: this.props.x
        },
        yAxis: {},
        series: []
      };

      // Update formatters
      if (this.props.format) {
        this.format = this.props.format;
      }

      // Add all supplied series
      options.series = this.createSeries();

      this.instance.setOption(options);
    }
  }

  render() {
    // Second render make sure to update values
    this.updateChart();

    const style = {
      width: "100%",
      height: "100%"
    };
    return <div ref={this.reference} style={style} />;
  }
}

export default Bar;
