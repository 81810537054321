import React, { Component, Fragment } from "react";
import { Route, NavLink } from "react-router-dom";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
  Menu,
  MenuItem,
  Divider,
  IconButton
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import classNames from "classnames";

import Navigation from "../../navigation/Navigation";
import Routes from "../../navigation/Routes";
import { Context as AuthenticationContext } from "../../authentication/Context";

const drawerWidth = 240;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    toolbar: {
      paddingRight: 24 // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36
    },
    menuButtonHidden: {
      display: "none"
    },
    title: {
      flexGrow: 1
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing.unit * 7,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing.unit * 9
      }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3,
      height: "100vh",
      overflow: "auto"
    },
    chartContainer: {
      marginLeft: -22
    },
    tableContainer: {
      height: 320
    },
    h5: {
      marginBottom: theme.spacing.unit * 2
    }
  });

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{}> & {
    signOut: () => void;
  };

interface State {
  open: boolean;
  isSettingsMenuOpen: boolean;
  menuAnchorElement: HTMLDivElement | null;
}

class Main extends Component<Props, State> {
  state = {
    open: true,
    isSettingsMenuOpen: false,
    menuAnchorElement: null
  };
  static contextType: typeof AuthenticationContext = AuthenticationContext;

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleSignOut = () => {
    this.props.signOut();
  };

  handleSettingsToggle = (e: React.MouseEvent<HTMLDivElement>) => {
    const anchor = e.currentTarget;

    this.setState(prevState => ({
      isSettingsMenuOpen: !prevState.isSettingsMenuOpen,
      menuAnchorElement: prevState.isSettingsMenuOpen ? null : anchor
    }));
  };

  handleProfileClick = () => {
    this.props.history.push("/settings");
    this.setState({
      isSettingsMenuOpen: false,
      menuAnchorElement: null
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar
          position="absolute"
          className={classNames(
            classes.appBar,
            this.state.open && classes.appBarShift
          )}
        >
          <Toolbar
            disableGutters={!this.state.open}
            className={classes.toolbar}
          >
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              XS Partners
            </Typography>
            <Typography variant="subtitle1" color="inherit" noWrap>
              Welcome, {this.context.username}
            </Typography>
            <IconButton color="inherit" onClick={this.handleSettingsToggle}>
              <SettingsIcon />
            </IconButton>
            <Menu
              open={this.state.isSettingsMenuOpen}
              anchorEl={this.state.menuAnchorElement}
              onClose={this.handleSettingsToggle}
            >
              <MenuItem onClick={this.handleProfileClick}>
                <ListItemIcon>
                  <AccountBoxIcon />
                </ListItemIcon>
                <ListItemText inset primary="Profile" />
              </MenuItem>
              <MenuItem onClick={this.handleSignOut}>
                <ListItemIcon>
                  <PowerSettingsNewIcon />
                </ListItemIcon>
                <ListItemText inset primary="Logout" />
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.open && classes.drawerPaperClose
            )
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <Navigation />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Routes />
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Main));
