import React from "react";
import { Button, Fab, CircularProgress } from "@material-ui/core";
import { FabProps } from "@material-ui/core/Fab";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import blue from "@material-ui/core/colors/blue";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center"
    },
    wrapper: {
      margin: theme.spacing.unit,
      position: "relative"
    },
    fabProgress: {
      color: blue[500],
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1
    }
  });

type Props = FabProps &
  WithStyles<typeof styles> & {
    isLoading: boolean;
  };
class Loading extends React.Component<Props> {
  render() {
    const { isLoading, children, classes, ...props } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Fab {...props} disabled={isLoading}>
            {children}
          </Fab>
          {isLoading && (
            <CircularProgress
              thickness={2}
              className={classes.fabProgress}
              size={68}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Loading);
