import React, { Fragment } from "react";
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Collapse
} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import classNames from "classnames";
import { withSnackbar, WithSnackbarProps } from "notistack";

import SecurityIcon from "@material-ui/icons/Security";
import EmailIcon from "@material-ui/icons/Email";
import InfoIcon from "@material-ui/icons/Info";

import API from "../../api/Insights";
import LoadingButton from "../../button/Loading";
import Password from "./Password";
import Email from "./Email";
import Profile from "./Profile";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2
    }
  });

type Props = WithStyles<typeof styles> & WithSnackbarProps & {};

type State = {
  password: boolean;
  email: boolean;
  profile: boolean;
};

class Settings extends React.Component<Props, State> {
  state = {
    password: false,
    email: false,
    profile: false
  };

  toggleMenu = (item: keyof State) => {
    this.setState(
      (state, props) =>
        ({
          [item]: !state[item]
        } as Pick<State, keyof State>)
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <Paper className={classNames(classes.root, "paper-main")}>
              <div className="paper-header">
                <Typography variant="h5" className="title">
                  Settings
                </Typography>
              </div>
              <Grid container spacing={16}>
                <Grid item>
                  <List subheader={<ListSubheader>Settings</ListSubheader>}>
                    <ListItem
                      button
                      onClick={this.toggleMenu.bind(this, "password")}
                    >
                      <ListItemIcon>
                        <SecurityIcon />
                      </ListItemIcon>
                      <ListItemText primary="Password" />
                    </ListItem>
                    <ListItem>
                      <Collapse
                        in={this.state.password}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Password />
                      </Collapse>
                    </ListItem>
                    <ListItem
                      button
                      onClick={this.toggleMenu.bind(this, "email")}
                    >
                      <ListItemIcon>
                        <EmailIcon />
                      </ListItemIcon>
                      <ListItemText primary="Email" />
                    </ListItem>
                    <ListItem>
                      <Collapse
                        in={this.state.email}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Email />
                      </Collapse>
                    </ListItem>
                    <ListItem
                      button
                      onClick={this.toggleMenu.bind(this, "profile")}
                    >
                      <ListItemIcon>
                        <InfoIcon />
                      </ListItemIcon>
                      <ListItemText primary="Profile" />
                    </ListItem>
                    <ListItem>
                      <Collapse
                        in={this.state.profile}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Profile />
                      </Collapse>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(withSnackbar(Settings));
